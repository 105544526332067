import {set} from 'lodash';
import React from 'react';
import {
  Button,
  Image,
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import {WebView} from 'react-native-webview';

type V1Data = {
  id?: string;
  hash: string;
  answer: string;
  data: string;
  createdAt?: Date | Object;
  updatedAt?: Date | Object;
};

export interface ProtectedData {
  title: string;
  columns: {
    title: string;
    subTitle?: string;
    content:
      | {
          type: string;
          label: string;
          value: string;
          link?: string;
        }[]
      | string[];
  }[];
}

type ProtectedSectionProps = {
  children:
    | React.ReactNode
    | ((hiddenData: ProtectedData) => React.JSX.Element);
  setUnlocked: (unlocked: boolean) => void;
};

const ProtectedSection = ({children, setUnlocked}: ProtectedSectionProps) => {
  const [isUnlocked, setIsUnlocked] = React.useState(false);
  const [hash, setHash] = React.useState('');
  const [hiddenData, setHiddenData] = React.useState<ProtectedData>();
  const [imageUrl, setImageUrl] = React.useState('');
  const [userInput, setUserInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [keyboardVisible, setKeyboardVisible] = React.useState(false);
  const styles = useProtectedSectionStyles();

  React.useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true); // Keyboard is shown
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false); // Keyboard is hidden
      }
    );
  
    // Cleanup the listeners on unmount
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  React.useEffect(() => {
    if (!isUnlocked) {
      setLoading(true);
      fetch('/api/v1/captcha')
        .then(res => res.json())
        .then((data: V1Data) => {
          console.log('Captcha Data', data);
          setHash(data.hash);
          setImageUrl(data.data);
          setLoading(false);
        });
    }
  }, [isUnlocked]);

  React.useEffect(() => {
    if (error !== '') {
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  }, [error]);

  const handleUnlock = React.useCallback(() => {
    if (loading) return;
    if (userInput.length !== 4) return;
    setLoading(true);
    console.log('About to verify', {userInput, hash});
    fetch(`/api/v1/captcha/verify`, {
      method: 'POST',
      body: JSON.stringify({userInput, hash}),
      headers: {'Content-Type': 'application/json'},
    })
      .then(res => res.json())
      .then((data: {verified: boolean; data: ProtectedData}) => {
        if (data.verified) {
          setIsUnlocked(true);
          setUnlocked(true);
          setLoading(false);
          setHiddenData(data.data);
          console.log('ProtectedData', data.data);
        } else {
          setError('Wrong... iT\'s CaSe SenSitiVe');
        }
        setLoading(false);
      });
  }, [hash, userInput]);

  console.log({
    isUnlocked,
    hash,
    hiddenData,
    imageUrl,
    userInput,
    error,
    loading,
  });

  if (!isUnlocked) {
    if (loading)
      return (
        <Container>
          <Text>Loading...</Text>
        </Container>
      );
    if (error)
      return (
        <Container>
          <Text>{error}</Text>
        </Container>
      );
    return (
      <Container>
        {/* <Image source={{uri: imageUrl}} alt="captcha" /> */}
        {(Platform.OS === 'web' && (
          <div
            dangerouslySetInnerHTML={{__html: imageUrl}}
            style={styles.captchaImage}
          />
        )) || (
          <WebView
            originWhitelist={['*']}
            source={{html: `<html><body>${imageUrl}</body></html>`}}
            style={styles.captchaImage}
          />
        )}
        <TextInput
          value={userInput}
          onChange={e => setUserInput(e.nativeEvent.text)}
          style={[styles.textInput, error ? {borderColor: 'red'} : null]}
          placeholder='XXXX'
          placeholderTextColor={error ? 'red' : '#AAAAAA'}
          maxLength={4}
          multiline={false}
          keyboardType='ascii-capable'
          enterKeyHint='done'
          onSubmitEditing={handleUnlock}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoFocus={true}
        />
        <TouchableOpacity style={styles.button} onPress={handleUnlock}>
          <Text style={styles.buttonText}>View Info</Text>
        </TouchableOpacity>
      </Container>
    );
  }

  return (
    <>
      {typeof children === 'function'
        ? (children as (arg0?: ProtectedData) => void)(hiddenData)
        : children}
    </>
  );
};

const Container = ({children}: {children: React.ReactNode}) => {
  return <View style={useProtectedSectionStyles().container}>{children}</View>;
};

const useProtectedSectionStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 2/3,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    captchaImage: {
      flex: 2 / 3,
      height: 50,
      width: 150,
      resizeMode: 'contain',
      backgroundColor: 'transparent',
      margin: 'auto',
      alignSelf: 'flex-start',
    },
    textInput: {
      flex: 2 / 3,
      borderColor: 'black',
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderStartColor: 'transparent',
      borderEndColor: 'transparent',
      borderWidth: 1,
      padding: 10,
      width: 150,
      height: 50,
      fontSize: 44,
      lineHeight: 44,
      margin: 'auto',
      outline: 'none',
      alignSelf: 'flex-start',
      textAlign: 'center',
    } as TextStyle,
    button: {
      flex: 2 / 3,
      padding: 10,
      margin: 'auto',
      width: 150,
      height: 50,
      backgroundColor: 'rgb(0, 122, 204)',
      color: 'white',
      textAlign: 'center',
      marginVertical: 20,
    },
    buttonText: {
      color: 'white',
      textAlign: 'center',
      fontSize: 20,
      whiteSpace: 'nowrap',
      textShadowColor: 'darkblue',
      textShadowOffset: {width: 1, height: 1},
      textShadowRadius: 1,
      borderRadius: 8,
      margin: 'auto',
    } as TextStyle,
  });
};

export default ProtectedSection;
