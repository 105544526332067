import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Linking,
  useWindowDimensions,
  ImageBackground,
  Platform,
  TextStyle,
  Keyboard,
} from 'react-native';
import AnimatedSection from './AnimatedSection';
import experienceData from './ExperienceData';
import ExperienceItem from './ExperienceItem';
import useFontSize, {useFontSizes} from './useFontSize';
import ProtectedSection, {ProtectedData} from './ProtectedSection';

const B = ({children, ...props}: any) => (
  <Text
    {...props}
    style={[
      {fontWeight: 'bold', display: 'flex', flexDirection: 'row'},
      props.style,
    ]}>
    {children}
  </Text>
);

const BInline = ({children, ...props}: any) => (
  <Text
    {...props}
    style={[
      {fontWeight: 'bold', display: 'inline'},
      props.style,
    ]}>
    {children}
  </Text>
);

const BB = ({children, ...props}: any) => (
  <Text
    {...props}
    style={[
      {
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 20,
        fontVariant: ['small-caps'],
      },
      props.style,
    ]}>
    {children}
  </Text>
);

const S = ({children, ...props}: any) => {
  const small = useFontSize(useFontSize.small);
  return (
    <Text
      {...props}
      style={[
        {fontSize: small, display: 'flex', flexDirection: 'row'},
        props.style,
      ]}>
      {children}
    </Text>
  );
};

const P = ({children, ...props}: any) => {
  const paragraph = useFontSize(useFontSize.paragraph);
  return (
    <Text
      {...props}
      style={[
        {fontSize: paragraph, display: 'flex', flexDirection: 'row'},
        props.style,
      ]}>
      {children}
    </Text>
  );
};
const D = ({children, fontSize = undefined, ...props}: any) => {
  return (
    <Text
      {...props}
      style={[
        {fontSize, display: 'flex', flexDirection: 'column'},
        props.style,
      ]}>
      {children}
    </Text>
  );
};

const NoWrap = ({children, ...props}: any) => {
  return (
    <Text
      {...props}
      style={[
        {
          display: 'flex',
          flexDirection: 'column',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        props.style,
      ]}>
      {children}
    </Text>
  );
};

const App = () => {
  const styles = useStyles();
  const {width, height} = useWindowDimensions();
  const titleSize = useFontSize(useFontSize.title);
  const [unlocked, setUnlocked] = React.useState(false);
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.header}>
        <Image
          style={styles.profilePic}
          source={require('./assets/profile-pic.png')} // Use the correct relative path
        />
        <SnapTitle fontSize={titleSize}>Michael Simoneau</SnapTitle>
        <Text style={styles.title}>
          <NoWrap>Leader, Inventor & Investor</NoWrap>
          <NoWrap>Innovator & Expert Engineer</NoWrap>
          <NoWrap>Founder @ Enigma Key Co.</NoWrap>
        </Text>
      </View>

      <View style={styles.body}>
        <AnimatedSection style={styles.contactInfoSection}>
          <View style={styles.contactInfo}>
            <ImageBackground
              source={require('./assets/profile-pic.png')}
              imageStyle={{
                opacity: 0.2,
                ...Platform.select({
                  web: {
                    objectFit: 'cover',
                    filter: 'grayscale(50%) brightness(72%)',
                  },
                }),
              }}
              style={{
                width: width / 3,
                height: '100%',
                position: 'absolute',
                right: 0,
              }}>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: 20,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  zIndex: 999,
                  backgroundColor: '#FFFFFF',
                }}></View>
            </ImageBackground>
            <Text style={styles.contactTitle}>Contact Me:</Text>
            <View
              style={[
                styles.contactBody,
                unlocked
                  ? null
                  : {
                      flex: 2 / 3,
                      justifyContent: 'flex-start',
                      maxWidth: '60%',
                    },
              ]}>
              <ProtectedSection setUnlocked={setUnlocked}>
                {({columns}) => (
                  <>
                    {columns.map(({title, subTitle, content}, index) => (
                      <View style={styles.contactColumn} key={index}>
                        <Text
                          style={[
                            styles.contactColumnTitle,
                            subTitle ? {marginBottom: 0} : null,
                          ]}>
                          {title}
                        </Text>
                        {subTitle && (
                          <Text style={styles.contactColumnSubTitle}>
                            {subTitle}
                          </Text>
                        )}
                        {(
                          content as {
                            type: string;
                            label: string;
                            value: string;
                            link?: string;
                          }[]
                        ).map(
                          (
                            {
                              type,
                              label,
                              value,
                              link,
                            }: {
                              type: string;
                              label: string;
                              value: string;
                              link?: string;
                            },
                            i,
                          ) => (
                            <D
                              key={i}
                              onPress={
                                link ? () => Linking.openURL(link) : undefined
                              }>
                              <B>{label}:</B>
                              <P style={{marginLeft: 10}}>{value}</P>
                            </D>
                          ),
                        )}
                      </View>
                    ))}
                  </>
                )}
              </ProtectedSection>
            </View>
          </View>
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={[styles.sectionTitle, {fontSize: 22}]}>PERSONAL SUMMARY</Text>
          <Text style={styles.sectionParagraph}>
            I leverage over 20 years of extensive experience in mobile, web, and
            native application development to create user-friendly and secure
            applications that enhance the user experience. My expertise spans
            DevOps, Hybrid Mobile, Native Mobile, Full-Stack, and Web3
            engineering.
          </Text>
          <Text style={styles.sectionParagraph}>
            <BB>Technical Skills:</BB>
          </Text>
          <Text style={styles.sectionParagraph}>
            <B>Languages & Platforms:</B> React-Native, React-Native-Web,
            Javascript, TypeScript, Node.js, React, Redux, Angular, Ionic,
            Python, Solidity, Java, Kotlin, Swift, Xcode, UIKit, PHP, HTML5, and
            MVC, MV**.
          </Text>
          <Text style={styles.sectionParagraph}>
            <B>Deep Expertise:</B> With a strong command of these technologies,
            I have led and contributed to numerous successful projects.
          </Text>
          <Text style={styles.sectionParagraph}>
            <BB>Passion for Learning:</BB>
          </Text>
          <Text style={styles.sectionParagraph}>
            I am passionate about advancing my skills in leadership, business,
            and engineering. I continuously seek to learn new technologies and
            best practices, always striving to keep learning and teaching
            myself. I am a lifelong learner and a dedicated mentor.
          </Text>
          <Text style={styles.sectionParagraph}>
            <BB>Teamwork & Innovation:</BB>
          </Text>
          <Text style={styles.sectionParagraph}>
            I thrive in a collaborative environment that values innovation and
            quality. Working with a passionate team that shares these values is
            one of my greatest joys.
          </Text>
          <Text style={styles.sectionParagraph}>
            <BB>Career Goal:</BB>
          </Text>
          <Text style={styles.sectionParagraph}>
            My goal is to continue to grow as a leader and innovator in the tech
            industry. I am excited to take on new challenges and opportunities
            that allow me to leverage my skills and experience to create
            innovative solutions.
          </Text>
          <Text style={styles.sectionParagraph}>
            I look to find a position that allows me to grow and develop my
            skills in a supportive and collaborative environment. I am excited
            to continue to learn and grow as a professional and as a person,
            particularly in the areas of leadership, business, and engineering
            (software, electrical, and mechanical.. even quantum).
          </Text>
          <Text style={styles.sectionParagraph}>
            My interest lies heavily in AI and Machine Learning, and I am
            excited to learn more about these fields, hopefully by incorporating
            them into my work.
          </Text>
          <Text style={styles.sectionParagraph}>
            I am looking for a position as a Senior Software Engineer, Lead or
            Principal Engineer, or a similar role that allows me to leverage my
            skills and experience to create innovative solutions, particularly
            with <BInline>React-Native or other Mobile technologies.</BInline>
          </Text>
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Experience</Text>
          {experienceData.map((item, index) => (
            <ExperienceItem
              key={index}
              position={item.position}
              company={item.company}
              duration={item.duration}
              location={item.location}
              responsibilities={item.responsibilities}
            />
          ))}
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Top Skills</Text>
          <Text style={styles.sectionParagraph}>
            TypeScript, JavaScript, Node.js, React, React Native, Swift, Xcode,
            MV*
          </Text>
          <Text style={styles.sectionTitle}>Other Skills</Text>
          <View style={styles.skillsGrid}>
            <Text style={styles.skillItem}>✓ React Native</Text>
            <Text style={styles.skillItem}>✓ Native iOS</Text>
            <Text style={styles.skillItem}>✓ Swift</Text>
            <Text style={styles.skillItem}>✓ UIKit</Text>
            <Text style={styles.skillItem}>✓ Certificate Pinning</Text>
            <Text style={styles.skillItem}>✓ Encryption</Text>
            <Text style={styles.skillItem}>✓ Hybrid Mobile Apps</Text>
            <Text style={styles.skillItem}>✓ iOS Target</Text>
            <Text style={styles.skillItem}>✓ Android Target</Text>
            <Text style={styles.skillItem}>✓ Custom Cordova Plugins</Text>
            <Text style={styles.skillItem}>✓ Angular</Text>
            <Text style={styles.skillItem}>✓ Ionic</Text>
            <Text style={styles.skillItem}>✓ Ionic Pro</Text>
            <Text style={styles.skillItem}>✓ SenchaJS</Text>
            <Text style={styles.skillItem}>✓ Native Android</Text>
            <Text style={styles.skillItem}>✓ Java 1.8+</Text>
            <Text style={styles.skillItem}>✓ Custom PDF Viewer</Text>
            <Text style={styles.skillItem}>✓ Content Providers</Text>
            <Text style={styles.skillItem}>✓ SAP MBO Client</Text>
            <Text style={styles.skillItem}>✓ DevOps</Text>
            <Text style={styles.skillItem}>✓ Version Control</Text>
            <Text style={styles.skillItem}>✓ Subversion</Text>
            <Text style={styles.skillItem}>✓ Git</Text>
            <Text style={styles.skillItem}>✓ Automated Testing</Text>
            <Text style={styles.skillItem}>✓ E2E Testing</Text>
            <Text style={styles.skillItem}>✓ Integration Testing</Text>
            <Text style={styles.skillItem}>✓ Unit Testing</Text>
            <Text style={styles.skillItem}>✓ Continuous Integration</Text>
            <Text style={styles.skillItem}>✓ Git Hooks</Text>
            <Text style={styles.skillItem}>✓ Jenkins</Text>
            <Text style={styles.skillItem}>✓ GitLab CI</Text>
            <Text style={styles.skillItem}>✓ Github Enterprise</Text>
            <Text style={styles.skillItem}>✓ Docker Containers</Text>
            <Text style={styles.skillItem}>✓ Continuous Deployment</Text>
            <Text style={styles.skillItem}>✓ Heroku</Text>
            <Text style={styles.skillItem}>✓ Firebase</Text>
            <Text style={styles.skillItem}>✓ Azure DevOps Pipelines</Text>
            <Text style={styles.skillItem}>✓ Google Cloud Platform</Text>
            <Text style={styles.skillItem}>✓ Agile Software Development</Text>
            <Text style={styles.skillItem}>✓ Scrum Team Leader</Text>
            <Text style={styles.skillItem}>✓ Scrum Workflow</Text>
            <Text style={styles.skillItem}>✓ Scrum Ceremonies</Text>
            <Text style={styles.skillItem}>✓ Azure DevOps</Text>
            <Text style={styles.skillItem}>✓ Jira</Text>
            <Text style={styles.skillItem}>✓ Node / NPM</Text>
            <Text style={styles.skillItem}>✓ Grunt / Gulp</Text>
            <Text style={styles.skillItem}>✓ HTML / CSS / Native JS</Text>
            <Text style={styles.skillItem}>✓ HTML5</Text>
            <Text style={styles.skillItem}>✓ Media / Canvas</Text>
            <Text style={styles.skillItem}>✓ Image Manipulation</Text>
            <Text style={styles.skillItem}>✓ SCSS / CSS</Text>
            <Text style={styles.skillItem}>✓ Transitions and Animations</Text>
            <Text style={styles.skillItem}>✓ Native JS</Text>
            <Text style={styles.skillItem}>✓ Fundamentals and OOP</Text>
            <Text style={styles.skillItem}>✓ No Libraries Needed!</Text>
            <Text style={styles.skillItem}>✓ jQuery / Prototype</Text>
            <Text style={styles.skillItem}>✓ TypeScript</Text>
            <Text style={styles.skillItem}>✓ AngularJS / Angular 2</Text>
            <Text style={styles.skillItem}>✓ RxJs - Observables</Text>
            <Text style={styles.skillItem}>✓ Component Architecture</Text>
            <Text style={styles.skillItem}>✓ React JS</Text>
            <Text style={styles.skillItem}>✓ Shopify Liquid</Text>
            <Text style={styles.skillItem}>✓ Full-Stack</Text>
            <Text style={styles.skillItem}>✓ Unix / Shell / SSH</Text>
            <Text style={styles.skillItem}>✓ Java</Text>
            <Text style={styles.skillItem}>✓ Android Native Development</Text>
            <Text style={styles.skillItem}>✓ Oracle Commerce Platform</Text>
            <Text style={styles.skillItem}>✓ Ruby</Text>
            <Text style={styles.skillItem}>✓ Mobile App Back-End</Text>
            <Text style={styles.skillItem}>✓ Desktop Application</Text>
            <Text style={styles.skillItem}>✓ Coffee / Slim / Haml / Jade</Text>
            <Text style={styles.skillItem}>✓ Node / Bower / Bundle / Rake</Text>
            <Text style={styles.skillItem}>✓ SQL / NoSQL</Text>
            <Text style={styles.skillItem}>✓ Postgres</Text>
            <Text style={styles.skillItem}>✓ MySQL</Text>
            <Text style={styles.skillItem}>✓ SQLite</Text>
            <Text style={styles.skillItem}>✓ Oracle</Text>
            <Text style={styles.skillItem}>✓ Firebase Firestore</Text>
            <Text style={styles.skillItem}>✓ SQL Server</Text>
            <Text style={styles.skillItem}>✓ MongoDB</Text>
            <Text style={styles.skillItem}>✓ PHP</Text>
            <Text style={styles.skillItem}>✓ Magento</Text>
            <Text style={styles.skillItem}>✓ Zend Framework</Text>
            <Text style={styles.skillItem}>✓ Node.JS</Text>
            <Text style={styles.skillItem}>✓ Mobile App Back-End</Text>
            <Text style={styles.skillItem}>✓ Full-Stack Web Server</Text>
          </View>
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}><BB>Spoken Languages</BB></Text>
          <Text style={styles.sectionParagraph}>
            English (Native or Bilingual)
          </Text>
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Education</Text>
          <Text style={styles.sectionParagraph}>
            University of London, Bachelor's degree, Computer Science (July
            2022 - Present)
          </Text>
          <Text style={styles.sectionParagraph}>
            Cleveland State University, N/A, Computer Science (2009 - 2011)
          </Text>
          <Text style={styles.sectionParagraph}>
            Cleveland State University, Computer Programming, Specific
            Applications (2009 - 2011)
          </Text>
        </AnimatedSection>

        {/* COMMENTED OUT FOR NOW
        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Projects</Text>
          <Text style={styles.sectionParagraph}>Project 1: Description of project 1</Text>
          <Text style={styles.sectionParagraph}>Project 2: Description of project 2</Text>
        </AnimatedSection> */}
      </View>
    </ScrollView>
  );
};

const SnapTitle = ({
  fontSize,
  children,
}: React.PropsWithChildren<{fontSize: number}>) => {
  const {width, height} = useWindowDimensions();
  const styles = useStyles();
  const snap = 8;
  const fullDeps = React.useMemo(
    () => [fontSize, width, height].filter(Boolean),
    [fontSize, width, height],
  );
  const adjustedValue = React.useRef(fontSize ?? 0);
  const calcNewValue = React.useCallback(() => {
    const newValue = Math.round(fontSize * ((width * 1.125) / height));
    adjustedValue.current = Math.round(newValue / snap) * snap;
  }, fullDeps);
  React.useEffect(() => {
    calcNewValue();
  }, fullDeps);
  calcNewValue();
  return (
    <Text style={[styles.name, {fontSize: adjustedValue.current}]}>
      {children}
    </Text>
  );
};

const useStyles = () => {
  const {width, height} = useWindowDimensions();
  const [keyboardVisible, setKeyboardVisible] = React.useState(false);
  const portrait = React.useRef(height > width * 1.125);
  const [isPortrait, setIsPortrait] = React.useState(portrait.current);
  const {title, subtitle, heading1, heading2, paragraph, small} =
    useFontSizes();

  React.useEffect(() => {
    if (keyboardVisible) return;
    portrait.current = height > width * 1.125;
  }, [keyboardVisible, height, width]);

  React.useEffect(() => {
    setIsPortrait(portrait.current);
  }, [portrait.current]);

  React.useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true); // Keyboard is shown
      },
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false); // Keyboard is hidden
      },
    );

    // Cleanup the listeners on unmount
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  return StyleSheet.create({
    container: {
      padding: 20,
      backgroundColor: '#f5f5f5',
    },
    header: {
      backgroundColor: '#007ACC',
      padding: 20,
      alignItems: 'center',
      borderRadius: 10,
      marginBottom: 20,
      zIndex: 999,
      shadowColor: '#000',
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2,
      flexDirection: isPortrait ? 'column' : 'row',
      justifyContent: 'center',
      overflow: 'hidden',
      alignContent: 'center',
    },
    body: {
      zIndex: 1,
      marginHorizontal: 20,
    },
    profilePic: {
      borderRadius: title * 3,
      width: title * 6,
      height: title * 6,
      marginBottom: 10,
    },
    name: {
      fontSize: title,
      fontWeight: 'bold',
      color: '#fff',
      textAlign: 'center',
      marginHorizontal: 24,
      flexWrap: 'nowrap',
      flexDirection: 'row',
      flex: 1,
    },
    title: {
      fontSize: subtitle,
      fontStyle: 'italic',
      color: '#fff',
      textAlign: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    contactTitle: {
      fontSize: heading1,
      fontWeight: 'bold',
      marginVertical: 10,
      marginTop: 20,
      paddingHorizontal: 15,
      flexDirection: 'row',
    },
    contactBody: {
      paddingHorizontal: 10,
      marginBottom: 20,
      fontSize: paragraph,
      flexDirection: isPortrait ? 'column' : 'row',
      justifyContent: 'space-between',
    },
    contactInfo: {
      textAlign: 'left',
      marginBottom: 20,
      backgroundColor: '#fff',
      borderRadius: 10,
      shadowColor: '#000',
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2,
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    contactInfoSection: {
      marginBottom: 20,
    },
    contactColumn: {
      flex: 1,
      paddingHorizontal: 10,
      textAlign: 'center',
      flexDirection: 'column',
      marginVertical: 10,
      width: (width / 3) * 2 - 20,
    },
    contactColumnTitle: {
      fontWeight: 'bold',
      marginVertical: 10,
      flexDirection: 'column',
      fontVariant: ['small-caps'],
      fontSize: heading2,
    },
    contactColumnSubTitle: {
      fontWeight: 'bold',
      marginBottom: 10,
      flexDirection: 'column',
      fontVariant: ['small-caps'],
      fontSize: small,
    },
    section: {
      backgroundColor: '#fff',
      borderRadius: 10,
      padding: 20,
      marginBottom: 20,
      shadowColor: '#000',
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2,
    },
    sectionTitle: {
      fontSize: heading1,
      fontWeight: 'bold',
      marginBottom: 10,
      marginLeft: -5,
    },
    sectionParagraph: {
      marginBottom: 10,
      fontSize: paragraph,
    },
    textJustify: {
      textAlign: 'justify',
    },
    bold: {
      fontWeight: 'bold',
    },
    skillsGrid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    skillItem: {
      width: '45%',
      marginVertical: 5,
    },
  });
};

export default App;
