const experienceData = [
    {
      position: "Chief Executive Officer",
      company: "Enigma Key Industries, LLC",
      duration: "February 2019 - Present (5 years 4 months)",
      location: "Chesterland, Ohio",
      responsibilities: [
        "• Led the company in all aspects of operations and strategic planning.",
        "• Oversaw the development and deployment of mobile and web applications.",
        "• Managed a team of developers and ensured the delivery of high-quality software products.",
        "• Engaged with clients to understand their needs and provided tailored technology solutions.",
        "• Drove innovation in blockchain and Web3 technologies, empowering the company to stay at the forefront of the industry."
      ].join(`
`),
    },
    {
      position: "Architect / Senior Lead Software Engineer",
      company: "StoneX Group Inc.",
      duration: "September 2022 - February 2024 (1 year 6 months)",
      location: "Chicago, Illinois, United States",
      responsibilities: [
        "• Led the development of a cross-platform mobile application using TypeScript and React Native.",
        "• Architected the system to ensure scalability and maintainability.",
        "• Coordinated with various stakeholders to integrate new features and improvements.",
        "• Developed core components and mentored junior developers.",
        "• Implemented best practices for code quality and performance."
      ].join(`
`),
    },
    {
      position: "Senior Software Engineer",
      company: "OneMain Financial",
      duration: "February 2022 - July 2023 (1 year 6 months)",
      location: "Remote",
      responsibilities: [
        "• Designed and developed software solutions to meet business requirements.",
        "• Ensured high performance and scalability of applications.",
        "• Collaborated with cross-functional teams to deliver projects on time.",
        "• Conducted code reviews and implemented automated testing frameworks.",
        "• Maintained and enhanced existing applications based on user feedback."
      ].join(`
`),
    },
    {
      position: "Lead iOS Engineer",
      company: "JPMorgan Chase & Co.",
      duration: "October 2019 - January 2022 (2 years 4 months)",
      location: "Chicago, Illinois, United States",
      responsibilities: [
        "• Developed the PaymentNet Mobile iOS application using Swift5 and XCode.",
        "• Ensured a seamless user experience through rigorous testing and quality assurance.",
        "• Integrated secure payment processing features and certificate pinning.",
        "• Coordinated with design and product teams to align the application with business goals.",
        "• Provided technical guidance and leadership to the iOS development team."
      ].join(`
`),
    },
    {
      position: "Mobile Application Developer",
      company: "Pulse",
      duration: "October 2018 - February 2019 (5 months)",
      location: "Chesterland, OH",
      responsibilities: [
        "• Replatformed mobile applications using React Native for major clients like Samsung and AT&T.",
        "• Collaborated with client teams to understand requirements and deliver customized solutions.",
        "• Ensured the applications met performance and security standards.",
        "• Implemented features and fixes based on user feedback.",
        "• Conducted testing and debugging to ensure application stability."
      ].join(`
`),
    },
    {
      position: "Mobile Application Developer",
      company: "Medical Mutual",
      duration: "March 2018 - August 2018 (6 months)",
      location: "Cleveland/Akron, Ohio Area",
      responsibilities: [
        "• Developed hybrid mobile applications using Cordova and Angular.",
        "• Ensured compliance with HIPAA and corporate security standards.",
        "• Worked closely with back-end teams to integrate APIs.",
        "• Conducted performance testing and optimization.",
        "• Provided support and maintenance for existing applications."
      ].join(`
`),
    },
    {
      position: "Software Engineer",
      company: "HyperProductive, LLC",
      duration: "February 2016 - January 2017 (1 year)",
      location: "Cleveland/Akron, Ohio Area",
      responsibilities: [
        "• Developed native and hybrid Android applications.",
        "• Worked on full-stack development using Java and AngularJS.",
        "• Collaborated with clients to gather requirements and deliver tailored solutions.",
        "• Implemented custom features and integrations based on client needs.",
        "• Conducted code reviews and ensured adherence to best practices."
      ].join(`
`),
    },
    {
      position: "IT Consultant",
      company: "RageOn!",
      duration: "July 2015 - January 2016 (7 months)",
      location: "Cleveland/Akron, Ohio Area",
      responsibilities: [
        "• Developed full-stack solutions using Ruby on Rails, React, and Shopify Liquid.",
        "• Worked on customizing and extending e-commerce platforms.",
        "• Implemented front-end and back-end features based on client requirements.",
        "• Ensured the performance and scalability of applications.",
        "• Provided technical support and troubleshooting for deployed applications."
      ].join(`
`),
    },
    {
      position: "Senior Lead Web Developer",
      company: "Rattan Outdoor Furniture",
      duration: "February 2014 - March 2015 (1 year 2 months)",
      location: "",
      responsibilities: [
        "• Led the development of the company’s website and e-commerce platform.",
        "• Implemented SEO strategies to improve search engine rankings.",
        "• Developed custom modules and integrations to enhance functionality.",
        "• Managed a team of developers and coordinated with other departments.",
        "• Provided training and support for web-related technologies."
      ].join(`
`),
    },
    {
      position: "Frontend / Backend Developer",
      company: "TapFury",
      duration: "December 2011 - February 2013 (1 year 3 months)",
      location: "Greater New York City Area",
      responsibilities: [
        "• Developed front-end and back-end solutions for various projects.",
        "• Used Zend Framework for PHP MVC and JavaScript for client-side development.",
        "• Integrated APIs and third-party services.",
        "• Conducted testing and debugging to ensure application stability.",
        "• Collaborated with designers and product managers to deliver high-quality software."
      ].join(`
`),
    },
    {
      position: "Lead Web Developer",
      company: "Sneak Attack Media",
      duration: "July 2011 - December 2011 (6 months)",
      location: "Greater New York City Area",
      responsibilities: [
        "• Created landing pages and small apps for Facebook to promote indie artists.",
        "• Worked closely with the marketing team to understand promotional needs.",
        "• Developed interactive features and user interfaces.",
        "• Ensured the performance and scalability of web applications.",
        "• Provided technical support and maintenance for deployed applications."
      ].join(`
`),
    },
  ];
  
  export default experienceData;